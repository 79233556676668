/**
 * Created by decipaliz on 15.08.2022
 */

var PackOfferComponent = cc.Node.extend({
    ctor: function (options) {
        this._super();

        options = this.options = options || {};
        this.styles = cleverapps.styles.PackOfferComponent[options.offerType] || cleverapps.styles.PackOfferComponent;
        this.onClicked = options.onClicked;
        this.bundleName = options.bundle;

        this.setVisible(false);
        this.createTap();
        this.createPack();
    },

    onClick: function () {
        cleverapps.audio.playSound(bundles.main.urls.click_effect);
        this.onClicked();
    },

    createPack: function () {
        var styles = this.styles;
        var packBundle = bundles[this.bundleName].jsons.pack;

        var pack = this.pack = new cleverapps.Spine(packBundle);
        pack.setAnimation(0, "animation", true);

        if (styles.pack.badge) {
            var badge = new TileBadge({
                text: this.options.badgeText,
                rotation: styles.pack.badge.rotation || 0,
                largeFont: styles.pack.badge.largeFont
            });
            badge.setPositionRound(styles.pack.badge);
            pack.addChild(badge);
        }

        cleverapps.UI.applyHover(pack, styles.pack.hoverRays ? {
            onMouseOver: function () {
                pack.applyInteractiveScale();
                pack.rays = AnimationsLibrary.rays(pack);
                pack.rays.setAnimation(0, "idle");
                pack.rays.setPositionRound(styles.pack.hoverRays.position);
            },
            onMouseOut: function () {
                pack.applyInteractiveScale();
                pack.rays.setAnimation(0, "close");
                pack.rays.setCompleteListenerRemove();
                pack.rays = undefined;
            }
        } : {});
        cleverapps.UI.onClick(pack, this.onClick.bind(this));

        this.addChild(pack);
    },

    createTap: function () {
        var styles = this.styles;

        var tapContainer = new cc.Node();
        tapContainer.setPositionRound(styles.tap);
        tapContainer.setRotation(styles.tap.rotation);

        var tap = new cleverapps.Spine(bundles[this.bundleName].jsons.pack_tap);
        tap.scaleX = styles.tap.scaleX || -Math.abs(tap.scaleX);
        tap.setPositionRound(styles.tap.animation);
        tap.setAnimation(0, "animation", true);
        tapContainer.addChild(tap);

        if (styles.pack.title) {
            var title = this.title = cleverapps.UI.generateOnlyText("FeastMissionWindow.tap", cleverapps.styles.FONTS.FEAST_MISSION_PACK_BUTTON);
            title.setPositionRound(styles.pack.title);
            title.fitTo(styles.pack.title.width);
            tapContainer.addChild(title);
        }

        this.addChild(tapContainer, styles.tap.zIndex);
        cleverapps.UI.onClick(tap, this.onClick.bind(this), {
            interactiveScale: false
        });
    },

    show: function (animate) {
        if (!animate) {
            this.setVisible(true);
            return;
        }

        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0, 0),
            new cc.Show(),
            new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut())
        ));
    },

    hide: function () {
        this.setVisible(false);
    },

    getStyles: function () {
        return this.styles.pack;
    },

    getOverlapNode: function () {
        return this.styles.isOverlapNode && this.pack;
    }
});

PackOfferComponent.checkBundles = function (bundleName) {
    return bundles[bundleName].jsons.pack && bundles[bundleName].jsons.pack_tap;
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FEAST_MISSION_PACK_BUTTON: {
        size: 70,
        color: new cc.Color(182, 99, 52, 255),
        shadow: {
            color: new cc.Color(126, 70, 37, 255),
            direction: cc.size(0, 2),
            blur: 1
        }
    }
});

cleverapps.styles.PackOfferComponent = {
    pack: {
        offsetX: -50,
        offsetY: 100,

        title: {
            x: { align: "center", dx: -17 },
            y: { align: "center", dy: 24 },
            width: 200
        },
        visibleResolutionModes: [
            cleverapps.WideMode.SQUARE,
            cleverapps.WideMode.HORIZONTAL
        ],
        spaceRequired: 0
    },

    tap: {
        x: { align: "left", dx: -45 },
        y: { align: "center", dy: 260 },
        rotation: 0,
        animation: {
            x: { align: "left", dx: 0 },
            y: { align: "top", dy: 0 }
        }
    }
};

// eslint-disable-next-line max-len
cleverapps.styles.PackOfferComponent[Offers.TYPES.SNAILFEAST] = cleverapps.styles.PackOfferComponent[Offers.TYPES.SNAILFEAST_DRAGONIA3] = cleverapps.styles.PackOfferComponent[Offers.TYPES.SNAILFEAST_DRAGONIA2] = cleverapps.styles.PackOfferComponent[Offers.TYPES.SNAILFEAST_UNDERSEA3] = cleverapps.styles.PackOfferComponent[Offers.TYPES.SNAILFEAST_HALLOWEEN] = cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent, {
    pack: {
        offsetY: 210,
        offsetX: 130,

        title: {
            x: { align: "center", dx: 225 },
            y: { align: "center", dy: -90 }
        }
    },

    tap: {
        x: { align: "left", dx: -240 },
        y: { align: "top", dy: 430 },
        animation: {
            x: { align: "left", dx: 0 },
            y: { align: "top", dy: 0 }
        }
    }
}, true);

cleverapps.styles.PackOfferComponent[Offers.TYPES.STICKERS_PACK] = cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent, {
    pack: {
        offsetX: 80,
        offsetY: 440,
        badge: {
            x: { align: "right", dx: -90 },
            y: { align: "top", dy: 65 },
            rotation: 30
        },
        title: undefined,
        hoverRays: {
            position: {
                x: { align: "center", dx: -40 },
                y: { align: "center" }
            }
        }
    },
    tap: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 },
        rotation: 0,
        animation: {
            x: { align: "center", dx: -10 },
            y: { align: "center", dy: -140 }
        },
        scaleX: 1
    },
    isOverlapNode: true
}, true);

cleverapps.styles.PackOfferComponent[Offers.TYPES.PINBALL_PACK] = cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent, {
    pack: {
        offsetX: 690,
        offsetY: 600,
        badge: {
            x: { align: "right", dx: 0 },
            y: { align: "top", dy: 85 },
            largeFont: true,
            rotation: 30
        },
        title: undefined,
        spaceRequired: 50,
        hoverRays: undefined
    },
    tap: {
        x: { align: "center", dx: 15 },
        y: { align: "center", dy: -340 },
        rotation: 0,
        animation: {
            x: { align: "center", dx: -20 },
            y: { align: "center", dy: -50 }
        },
        scaleX: 1
    },
    isOverlapNode: true
}, true);